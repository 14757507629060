import React from 'react';
import { connect } from 'react-redux'
import { getImage } from 'components/Avatar';
import IntlMessages from 'components/IntlMessages';

const UserInfoPopup = props => {
  const { firstName, lastName } = props;
  return (
    <div>
      <div className="user-profile">
        <img className="user-avatar border-0 size-40 rounded-circle"
          src={getImage(firstName, lastName)}
          alt="User" />
        <div className="user-detail ml-2">
          <h4 className="user-name mb-0">{firstName} {lastName}</h4>
          <small>Administrator</small>
        </div>
      </div>
      <span className="jr-link dropdown-item text-muted">
        <i className="zmdi zmdi-face zmdi-hc-fw mr-1" />
        <IntlMessages id="popup.profile" />
      </span>
      <span className="jr-link dropdown-item text-muted">
        <i className="zmdi zmdi-settings zmdi-hc-fw mr-1" />
        <IntlMessages id="popup.setting" />
      </span>
      <span className="jr-link dropdown-item text-muted" onClick={() => {
        props.logOut();
      }}>
        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
        <IntlMessages id="popup.logout" />
      </span>
    </div>
  );
}

export default UserInfoPopup;
