import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '58ec973f-9c94-4e25-8019-d22e334fc973',
  clientId: 'f7cc813b-a1e3-44e3-9edd-1276891a2e08',
  endpoints: {
    api: 'f7cc813b-a1e3-44e3-9edd-1276891a2e08',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
export const invalidateResourceTokens = () => {
  authContext.invalidateResourceTokens();
} 
export const authUser = () => authContext?._user?.profile || {};
