import React from 'react';
import { usePageContext } from './redux/PageContext';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


export default function Icons() {
  const { pageState: { icons } } = usePageContext();

  return (
    <>
      {
        icons.map(icon => {
          const {key, url, className, text, phone} = icon;
          const liClassName = `list-inline-item ${key}`;
          return (
              <li className={liClassName} key={key} style={{margin: '10px'}}>
                  <img src={url} className={className} />
                  <span className="icon-text">{text}</span>
                  <a className="icon-text" href={`tel:${phone}`}>{phone}</a>
              </li>
          )})
      }
    </>
  )
}

