import { 
  PAGE_TITLE,
  PAGE_SUBTITLE,
  PAGE_HEADER_CONTENT,
  PAGE_ADD_GROUP_BUTTONS, 
  PAGE_ACTIVE_GROUP_BUTTON, 
  PAGE_CLEAR_PAGE,
  PAGE_ADD_ICON_BUTTONS,
  PAGE_CLEAR_ICON_BUTTONS,
  PAGE_ADD_ICON_BUTTON_EVENT,
  PAGE_CLEAR_ICON_BUTTON_EVENTS,
  PAGE_REMOVE_ICON_BUTTON,
  PAGE_REMOVE_ICON_BUTTON_EVENT,
  PAGE_NODE_ENV,
  PAGE_CHANGE_LOGO_VISIBLE,
  PAGE_ADD_ICON,
  PAGE_SET_SEARCHBOX_VISIBLE
} from './PageConstants';
import { statement } from '@babel/template';

export const pageTitle = (title) => {
  return {
      type: PAGE_TITLE,
      title
  };
};

export const pageSubtitle = (subtitle) => {
  return {
      type: PAGE_SUBTITLE,
      subtitle
  };
};

export const pageHeaderContent = (headerContent) => {
  return {
      type: PAGE_HEADER_CONTENT,
      headerContent
  };
};

export const addGroupButtons = (buttons) => {
  return {
    type: PAGE_ADD_GROUP_BUTTONS,
    buttons
  }
}

export const addIconButtons = iconButtons => {
  return {
    type: PAGE_ADD_ICON_BUTTONS,
    iconButtons
  }
}

export const removeIconButton = key => {
  return {
    type: PAGE_REMOVE_ICON_BUTTON,
    key
  }
}

export const activeGroupButton = activeButton => {
  return {
    type: PAGE_ACTIVE_GROUP_BUTTON,
    activeButton
  }
}

export const clearIconButtons = () => {
  return {
    type: PAGE_CLEAR_ICON_BUTTONS
  }
}

export const changeLogoVisible = value => {
  return {
    type: PAGE_CHANGE_LOGO_VISIBLE,
    value
  }
}

export const addIcon = value => {
  return {
    type: PAGE_ADD_ICON,
    value
  }
}

export const clearPage = () => {
  return {
    type: PAGE_CLEAR_PAGE
  }
}

export const addIconButtonEvent = (key, handler) => {
  return {
    type: PAGE_ADD_ICON_BUTTON_EVENT,
    key,
    handler
  }
}

export const clearIconButtonEvents = () => {
  return {
    type: PAGE_CLEAR_ICON_BUTTON_EVENTS
  }
}

export const removeIconButtonEvent = key => {
    return {
      type: PAGE_REMOVE_ICON_BUTTON_EVENT,
      key
    }
}

export const pageNodeEnv = NODE_ENV => {
  return {
    type: PAGE_NODE_ENV,
    NODE_ENV
  }
}

export const mapSearchBox = value => {
  return {
    type: PAGE_SET_SEARCHBOX_VISIBLE,
    value
  }
}
