import React, { useEffect, useReducer } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PageContext, usePageContext, PageReducer } from 'components/Page';
import configureStore, { history } from './store';
import App from './containers/App';
import CacheBuster from 'components/CacheBuster';
import 'ag-grid-enterprise';
import { LicenseManager } from "ag-grid-enterprise";
import useApi, { CallApi, errorNotification } from 'components/Api';
import { LoaderContainer } from 'components/Loader';
import {pageSettings} from 'Settings/Page';
import { DriveEtaOutlined } from '@material-ui/icons';

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");

// configuration Redux store
export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = {...usePageContext(), ...pageSettings};
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);
  const { callApi } = useApi(process.env.REACT_APP_PROXY);

  return (
    <CacheBuster packageJson={import('../package.json')}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
            <PageContext.Provider value={{ pageState, pageDispatch }}>
              <App {...props} />
            </PageContext.Provider>
        );
      }}
    </CacheBuster>
  )
}

export default function MainApp() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={Apps} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}


