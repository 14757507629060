import * as React from 'react';
import { usePageContext } from 'components/Page';
import { Link, withRouter } from 'react-router-dom';

export default function HeaderLogo() {
  const { pageState } = usePageContext();

  if (!pageState.logoVisible)
    return ( <Link  className="d-sm-block" to="/">
    <img src='https://rhplus.blob.core.windows.net/svg-rhplus-clients/tk-maxx.svg' alt="TKMaxx" title="TKMaxx" className="store-logo" />
  </Link>);

  return (
    <Link className="app-logo d-sm-block" to="/">
      <img src={require("assets/images/logo.png")} alt="RHplus" title="RHplus" />
    </Link>)
}