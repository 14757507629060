export const pageSettings = {
  title: '',
  subtitle: '',
  buttons: [],
  activeButton: null,
  iconButtons: [],
  handlers: [],
  icons: [],
  NODE_ENV: 'production',
  logoVisible: true
}