import * as React from 'react';
import {LoaderContext} from './reducer/Context';
// import PubSub from 'components/PubSub';
import uuidv4 from 'uuid/v4';

// var pubsub = new PubSub({}, '');
const _key = uuidv4();

const LoaderContainer = (props) => {
  const { children, pubnubConfig, channel } = props;
  // pubsub = new PubSub(pubnubConfig, channel);
  
  // React.useEffect(() => {
  //   pubsub.addListener({
  //     message: messageObject => {
  //       const {channel, message: {Key, Message}} = messageObject;
  //       if (Key == _key) 
  //         setMessage(Message);  
  //     }
  //   })
  // }, [])


  const setMessage = message => {
    setValueContext(state => ({ ...state, message}));
  } 

  const setLoading = loading => {
    if (loading)
      setValueContext(state => ({ ...state, loading}));
    else
      setValueContext(state => ({ ...state, loading, message: ''}));

  } 
  
  const valueContextInit = {
    key: _key,
    message: '',
    loading: false,
    setMessage,
    setLoading
  }
  
  const [valueContext, setValueContext] = React.useState(valueContextInit);
  return (
    <>
      <LoaderContext.Provider value={valueContext}>
          {children}
      </LoaderContext.Provider>
    </>
  )
}

export default LoaderContainer;