import * as React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import RhPlusAvatar from 'components/Avatar';
import UserInfoPopup from './UserInfoPopup';

const DetailedUserInfo = props => {
  const [userInfo, setUserInfo] = React.useState(false);
  const { userSignOut, authContext } = props;
  const { given_name, family_name } = authContext?._user?.profile || {};

  return (
    <Dropdown
      className="quick-menu"
      isOpen={userInfo}
      toggle={() => setUserInfo(!userInfo)}>

      <DropdownToggle
        className="d-inline-block"
        tag="span"
        data-toggle="dropdown">
        <RhPlusAvatar iconButtonClassName="size-30" firstName={given_name} lastName={family_name} />
      </DropdownToggle>

      <DropdownMenu right>
        <UserInfoPopup firstName={given_name} lastName={family_name} logOut={() => {
          userSignOut();
          authContext.logOut()
        }} />
      </DropdownMenu>
    </Dropdown>
  )
}

export default DetailedUserInfo;



// <Dropdown
// className="quick-menu"
// isOpen={userInfo}
// toggle={setUserInfo(!userInfo)}>

// <DropdownToggle
//   className="d-inline-block"
//   tag="span"
//   data-toggle="dropdown">
//   <RhPlusAvatar iconButtonClassName="size-30" firstName={given_name} lastName={family_name} />
// </DropdownToggle>

// <DropdownMenu right>
//   <UserInfoPopup firstName={given_name} lastName={family_name} logOut={() => {
//     userSignOut();
//     authContext.logOut()
//   }} />
// </DropdownMenu>
// </Dropdown>
