import * as React from 'react';
import { useContext } from 'react';
import PageContext from './redux/PageContext';
import { 
  pageTitle,
  pageSubtitle, 
  pageHeaderContent,
  addIconButtons, 
  clearIconButtons,
  addIconButtonEvent,
  clearIconButtonEvents,
  removeIconButton,
  removeIconButtonEvent,
  clearPage,
  changeLogoVisible,
  addIcon,
  mapSearchBox
} from './redux/PageAction';

export const usePageContext = () => {
  return useContext(PageContext);
}

function usePage() {
  const { pageState, pageDispatch } = usePageContext();
  const setPageTitleDispatch = title => pageDispatch(pageTitle(title));
  const setPageSubtitleDispatch = subtitle => pageDispatch(pageSubtitle(subtitle));
  const setPageHeaderContentDispatch = content => pageDispatch(pageHeaderContent(content));
  const clearPageTitleDispatch = () => pageDispatch(pageTitle(''));
  const addIconButtonsDispatch = buttons => pageDispatch(addIconButtons(buttons));
  const clearIconButtonsDispatch = () => pageDispatch(clearIconButtons());
  const removeIconButtonDispatch = key => pageDispatch(removeIconButton(key));
  const addIconButtonEventDispatch = (key, handler) => pageDispatch(addIconButtonEvent(key, handler));
  const clearIconButtonEventsDispatch = () => pageDispatch(clearIconButtonEvents());
  const removeIconButtonEventDispatch = key => pageDispatch(removeIconButtonEvent(key));
  const changeLogoVisibleDispatch = value => pageDispatch(changeLogoVisible(value));
  const addIconDispatch = value => pageDispatch(addIcon(value));
  const mapSearchBoxDispatch = value => pageDispatch(mapSearchBox(value));

  React.useLayoutEffect(() => {
    clearPageTitleDispatch();
    clearIconButtonsDispatch();  
  }, [])

  const iconButtons = {
    addDocument: {
      type: 'custom',
      key: 'addDocument',
      className: `icomoon-add-document icomoon-md`
    },
    camera: {
      type: 'custom',
      key: 'camera',
      className: `icomoon-camera icomoon-md`
    },
    save: {
      type: 'custom',
      key: 'save',
      className: `icomoon-save icomoon-md`
    },
  }

  const addIconButton = ({key, className, func}) => {
    addIconButtonsDispatch([{key,className, func}]);
  }

  const addMaterialIconButton = (key) => {
    addIconButtonsDispatch([{key, type: 'material'}]);
  }

  return {
    setPageTitle: setPageTitleDispatch,
    clearPageTitle: clearPageTitleDispatch,
    setPageSubtitle: setPageSubtitleDispatch,
    setPageHeaderContent: setPageHeaderContentDispatch,
    addIconButtons: addIconButtonsDispatch,
    addMaterialIconButton,
    addIconButton,
    clearIconButtons: clearIconButtonsDispatch,
    removeIconButton: removeIconButtonDispatch,
    iconButtons,
    iconState: pageState,
    addIconButtonEvent: addIconButtonEventDispatch,
    clearIconButtonEvents: clearIconButtonEventsDispatch,
    removeIconButtonEvent: removeIconButtonEventDispatch,
    changeLogoVisible: changeLogoVisibleDispatch,
    addIcon: addIconDispatch,
    mapSearchBox: mapSearchBoxDispatch
  };
}

export default usePage;