import * as React from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'components/IntlMessages';
import classNames from 'classnames';
import {getImage} from 'components/Avatar';

export default function UserInfo(props) {
  const [ open, setOpen ] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {firstName, lastName, logOut} = props;

  const handleClick = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const directionClassName = (direction) => {
    var classname = direction === 'left' ? 'user-profile-left' : 'user-profile-right'
    return classNames('user-profile', 'd-flex', 'flex-row', 'align-items-center', classname);
  }

  const { direction = 'left' } = props;

  return (
    <div className={classNames('user-profile', 'd-flex', 'flex-row', 'align-items-center', directionClassName(direction))}>
      <Avatar
        alt='...'
        src={getImage(firstName, lastName)}
        className="user-avatar" 
      />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>{firstName} {lastName}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu className="user-info"
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem>
        <MenuItem onClick={() => {
          logOut();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
}


