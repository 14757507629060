import { Button, notification } from 'antd'
// import "antd/dist/antd.css";
import {getErrorFromResponse} from './Api';
import {ArgsProps} from 'antd/lib/notification';
import './ant-notification.scss';
import * as React from 'react';

export const successNotification = (args) => {
  notification.success({
    ...args, 
    placement: 'topRight'
  });
}

export const errorNotification = (error) => {
  const key = `success_notification_${Date.now()}`;
  const btn = (<Button type="primary" size="small" onClick={() => notification.close(key)}>I acknowledge</Button>)
  
  const {message, response} = getErrorFromResponse(error);
  notification.error({
    message,
    duration: 0,
    description: response.message,
    placement: 'topRight',
    btn,
    key
  });
};
