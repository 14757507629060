import React from 'react';
import {usePageContext} from './Page';
import Typography from '@material-ui/core/Typography';

export default function HeaderSubtitle() {
  const {pageState} = usePageContext();
  const {subtitle} = pageState;

  return (
    <Typography className="ml-2 header-subtitle"
      variant="subtitle2"
      color="#151414"
      style={{flex:1, fontSize: '10px'}}
    >
      {subtitle} {process.env.REACT_APP_PROXY.includes("localhost") && "[Development]"}
    </Typography>
    
  );
}
