import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';

export const getColor = text => {
  var number = parseFloat((Number((text || 'A').charCodeAt(0)) - 30) / 100);
  return ((1 << 24) * number | 0).toString(16);
}

export const getImage = (firstName = '', lastName = '') => {
  let name;
  if (!(firstName && lastName))
    name = 'NA';
  else
    name = firstName.charAt(0) + lastName.charAt(0);

  var color = getColor(name);
  var url = `https://dummyimage.com/150x150/${color}/fff.png&text=+${name}+`;
  return url;
}

export default function RhPlusAvatar({ iconButtonClassName, firstName, lastName }) {
  return (
    <IconButton className={classNames("icon-btn", "size-30", iconButtonClassName)}>
      <Avatar
        alt='...'
        src={getImage(firstName, lastName)}
        className={classNames("size-30", iconButtonClassName)}
      />
    </IconButton>
  )

}
