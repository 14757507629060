import React from 'react';
import { usePageContext } from 'components/Page';
import { Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';




export default function HeaderSearchBox() {
    const [state, setState] = React.useState({ companyName: '', projectName: '' });
    const { pageState } = usePageContext();

    const handleCompanyNameChange = (e) => {
        setState({ companyName: e.target.value });
    }

    const handleProjectNameChange = (e) => {
        setState({ projectName: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const companyName = state.companyName.trim();
        const projectName = state.projectName.trim();

        // onFormSubmit({ companyName: companyName, projectName: projectName });
        setState({ companyName: '', projectName: '' });
    }

    if (!pageState.mapSearchBox)
        return (<React.Fragment />);

    return (<div className="CompaniesForm form-inline">
        <form className="search-box-form" onSubmit={handleSubmit}>
            <Input className="search-box-input"
                type="text"
                placeholder="Search term"
                value={state.projectName}
                onChange={handleProjectNameChange}
            />
            <button className="search-button" type="submit"><SearchOutlined style={{verticalAlign: 'middle'}} /></button>
        </form>
    </div>)

}
