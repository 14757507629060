import React, {useContext} from 'react';
import {usePageContext} from './Page';
import Typography from '@material-ui/core/Typography';

export default function HeaderTitle() {
  const {pageState} = usePageContext();
  const {title} = pageState;

  return (
    <Typography className="ml-2"
      variant="h6"
      color="inherit"
      style={{flex:1, marginTop: '3px'}}
    >
      {title} {process.env.REACT_APP_PROXY.includes("localhost") && "[Development]"}
    </Typography>
    
  );
}


